/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.react-horizontal-scrolling-menu--inner-wrapper {
  justify-content: space-between;
}
.react-horizontal-scrolling-menu--scroll-container{
  max-width: 100%;
  width: unset;
}